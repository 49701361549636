
.disFlex[data-v-b4dba970] {
  width: calc(100% - 48px);
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
