.loginBox[data-v-6bc2799e] {
  width: 560px;
  height: 560px;
  border-radius: 24px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 20vh;
  right: 10vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login-main[data-v-6bc2799e] {
  margin: 0 auto;
  width: 430px;
}
.loginImg[data-v-6bc2799e] {
  width: 230px;
  height: 40px;
  display: block;
  margin: auto;
}
.loginTitle[data-v-6bc2799e] {
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #000;
  margin: 13px 0;
  font-size: 30px;
}
[data-v-6bc2799e] .el-input--small .el-input__inner {
  height: 52px;
  line-height: 52px;
}
.loginBtn[data-v-6bc2799e] {
  width: 100%;
  height: 52px;
  font-size: 16px;
}
.top-icon[data-v-6bc2799e] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
}
.qr-content[data-v-6bc2799e] {
  width: 180px;
  height: 180px;
  margin: 20px auto;
  position: relative;
}
.qr-content .code-modal[data-v-6bc2799e] {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 0px 0px 0px 0px;
  background: rgba(97, 97, 97, 0.5);
  z-index: 10;
}
.qr-content .qyCode-box[data-v-6bc2799e] {
  position: relative;
  height: 180px;
  overflow: hidden;
  top: 0;
}
.qr-content .qyCode-box .qwImage[data-v-6bc2799e] {
  position: absolute;
  top: -60px;
  left: -36px;
  width: 180px;
  height: 180px;
  -webkit-transform: scale(0.65, 0.65);
          transform: scale(0.65, 0.65);
}
.qr-title[data-v-6bc2799e] {
  text-align: center;
}