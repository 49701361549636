.login-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-weaper {
  margin: 0 auto;
  width: 500px;
}
.login-weaper .el-input-group__append {
  border: none;
}
.login-left,
.login-border {
  position: relative;
  min-height: 400px;
}
.login-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #2984f8;
  color: #fff;
  float: left;
  width: 380px;
  position: relative;
}
.login-left .img {
  width: 140px;
}
.login-time {
  position: absolute;
  left: 25px;
  top: 25px;
  width: 100%;
  color: #fff;
  font-weight: 200;
  opacity: 0.9;
  font-size: 18px;
  overflow: hidden;
}
.login-left .title {
  margin-top: 60px;
  text-align: center;
  color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 25px;
}
.login-border {
  border-left: none;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid #eeeeee;
  color: #fff;
  background-color: #fff;
  width: 90%;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.login-main {
  margin: 0 auto;
  width: 85%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.login-main > h3 {
  margin-bottom: 20px;
}
.login-main > p {
  color: #76838f;
}
.login-title {
  color: #7db8f5;
  margin: 25px 0 !important;
  font-weight: 400;
  font-size: 23px;
  text-align: center;
  letter-spacing: 4px;
}
.login-menu {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.login-menu a {
  color: #999;
  font-size: 12px;
  margin: 0px 8px;
}
.login-submit {
  width: 100%;
  height: 45px;
  border: 1px solid #a8d2fb;
  background: #4596f7;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  font-family: "neo";
  -webkit-transition: 0.25s;
  transition: 0.25s;
  border-radius: 5px !important;
}
.login-form {
  margin: 10px 0;
}
.login-form i {
  color: #333;
}
.login-form .el-form-item__content {
  width: 100%;
}
.login-form .el-form-item {
  margin-bottom: 12px;
}
.login-form .el-input input {
  text-indent: 5px;
  background: transparent;
  border-radius: 0;
  color: #333;
  border-bottom: 1px solid rgb(235, 237, 242);
}
.login-form .el-input .el-input__prefix i {
  padding: 0 5px;
  font-size: 16px !important;
}
.login-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 0 0 0 10px;
}
.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
  cursor: pointer !important;
}
.login_top {
  width: 80%;
  margin: auto;
  height: 120px;
  line-height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.login_top .login_middle_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.login_top .login_middle_top .login_top_img {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}
.login_top .login_middle_top .login_top_title {
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
}
.login_top .login_middle_top .login_top_desc {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}
.login_top .login_middle_top .login_middle_top_line {
  width: 2px;
  height: 30px;
  margin: 45px 30px;
  background-color: #ffffff;
}
.login_top .login_top_line {
  width: 25%;
  height: 2px;
  margin: 59px 0;
  background-color: #bed0f5;
}
.loginBgImg {
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0;
}
.login_main {
  width: 100%;
  height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.login_main .login_main_right {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  position: relative;
}
.languageRadio {
  text-align: center;
  margin-bottom: 16px;
}
.login_bottom_about {
  width: 100%;
  text-align: center;
}
.login_bottom_about p {
  margin: 10px;
}
.login_bottom_permit {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
}
.login_bottom_alert {
  width: 100%;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
}
.login_bottom_alert .keep_record_img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 1280px) {
.login-container {
    overflow: auto;
}
.login_top {
    height: 80px;
    line-height: 80px;
}
.login_top .login_top_line {
    margin: 39px 0;
}
.login_top .login_middle_top .login_top_img {
    width: 75px;
    height: 75px;
    margin: 2.5px;
}
.login_top .login_middle_top .login_top_desc {
    font-size: 20px;
}
.login_top .login_middle_top .login_top_title {
    font-size: 24px;
}
.login_top .login_middle_top .login_middle_top_line {
    margin: 25px 30px;
}
.login_main {
    height: 400px;
}
.loginBgImg {
    height: 400px;
}
.login-weaper {
    width: 380px;
}
.login-left,
  .login-border {
    min-height: auto;
}
.login-title {
    margin: 15px 0 !important;
    font-size: 20px;
}
.login-form .el-form-item {
    margin-bottom: 20px;
}
.login-submit {
    height: 40px;
    font-size: 16px;
}
.login_bottom_about p {
    margin: 0;
}
}
@media screen and (min-width: 1281px) and (max-width: 1536px) {
.login_main {
    height: 500px;
}
.loginBgImg {
    height: 500px;
}
.login_bottom_about p {
    margin: 7px 0;
}
.login-weaper {
    width: 430px;
}
.login-left,
  .login-border {
    min-height: 350px;
}
}