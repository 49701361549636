.topImg[data-v-acff684c] {
  width: 100%;
  height: 120px;
  background-image: url(../../static/img/appShowTop.bdbd6c69.png);
  background-size: contain;
  background-repeat-x: repeat;
  margin-bottom: 20px;
}
.disflex[data-v-acff684c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.topLeft[data-v-acff684c] {
  width: 60%;
}
.topLeft img[data-v-acff684c] {
  width: 100%;
}
.topRight[data-v-acff684c] {
  width: 40%;
}
.appLogo[data-v-acff684c] {
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
}
.appLogo img[data-v-acff684c] {
  width: 100%;
  height: 100%;
}
.appTitle[data-v-acff684c] {
  color: #000000;
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  margin-top: 5px;
}
.appSuit[data-v-acff684c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
}
.appSuit .suitBox[data-v-acff684c] {
  width: 50%;
  text-align: center;
}
.appSuit .suitBox img[data-v-acff684c] {
  display: block;
  margin: auto;
  margin-bottom: 24px;
  width: 70px;
  height: 70px;
}
.appSuit .suitBox .downloadImg[data-v-acff684c] {
  width: 100px;
  height: 100px;
  margin-top: 24px;
}
.appInformation[data-v-acff684c] {
  width: 50%;
  margin: 40px auto 0;
  border-top: 1px solid #e7ebed;
}
.appInformation .informationTitle[data-v-acff684c] {
  width: 100%;
  color: #0A0D26;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  margin: 40px auto 20px;
}
.appInformation .informationContent[data-v-acff684c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.appInformation .informationContent .version-info[data-v-acff684c] {
  width: 40%;
  margin-top: 5px;
  padding: 5px 0;
  border-bottom: 1px solid #e7ebed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.appInformation .appShowImg[data-v-acff684c] {
  width: 100%;
  height: 900px;
}
.bottomBox[data-v-acff684c] {
  width: 50%;
  padding: 30px 25%;
  background-color: #1f2730;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bottomBox .bottomContent a[data-v-acff684c] {
  color: #aaaaaa;
  font-size: 15px;
  cursor: pointer;
}