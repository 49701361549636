.error-page[data-v-1837a7ec] {
  background: #f0f2f5;
  margin-top: -30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.error-page .img[data-v-1837a7ec] {
  margin-right: 80px;
  height: 360px;
  width: 100%;
  max-width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.error-page .content h1[data-v-1837a7ec] {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.error-page .content .desc[data-v-1837a7ec] {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}