.social-container[data-v-2ffb9b82] {
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.social-container .iconfont[data-v-2ffb9b82] {
  color: #fff;
  font-size: 30px;
}
.social-container .container[data-v-2ffb9b82] {
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
}
.social-container .title[data-v-2ffb9b82] {
  text-align: center;
}